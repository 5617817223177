/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Dhvanith's Portfolio",
  description:
    "Passionate software developer dedicated to crafting elegant solutions and pushing the boundaries of innovation.",
  og: {
    title: "Dhvanith Unadkat Portfolio",
    type: "website",
    url: "http://dhvanith.com/",
  },
};

//Home Page
const greeting = {
  title: "Dhvanith Unadkat",
  logo_name: "DhvanithUnadkat",
  nickname: "",
  subTitle:
    "Passionate software developer dedicated to crafting elegant solutions and pushing the boundaries of innovation.",
  resumeLink:
    "https://drive.google.com/file/d/1viKlx1Msq5W6PZ397GAe_HaYmVO8FAjs/view?usp=sharing",
  portfolio_repository: "",
  githubProfile: "https://github.com/Dhvanith",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/Dhvanith",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/dhvanith/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:dhvanith@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Machine Learning & AI",
      imagePath: "machine_learning.svg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience of working with Computer Vision and AI projects",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Numpy",
          fontAwesomeClassname: "logos-numpy",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    {
      title: "Full Stack Development",
      imagePath: "fullstackdev.svg",
      skills: [
        "⚡ Building responsive website front end using ReactJS",
        "⚡ Developing android applications with Java",
        "⚡ Creating application backend using Firebase and SQL",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "##4479A1",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
      ],
    },
    {
      title: "Object Oriented Programming",
      imagePath: "objectprogramming.svg",
      skills: [
        "⚡ Skilled in Object-Oriented Programming principles, demonstrating expertise in designing and implementing well-structured, maintainable, and reusable code. ",
        "⚡ Experience with tools and technologies commonly used in Java development, such as IDEs, version control systems, build automation tools, and testing frameworks.",
        "⚡ Continuously learning and adapting to new technologies and industry trends",
      ],
      softwareSkills: [
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "##F05032",
          },
        },
        {
          skillName: "Gradle",
          fontAwesomeClassname: "simple-icons:gradle",
          style: {
            color: "#02303A",
          },
        },
        {
          skillName: "JUnit",
          fontAwesomeClassname: "simple-icons:junit5",
          style: {
            color: "#25A162",
          },
        },
        {
          skillName: "Intellij IDEA",
          fontAwesomeClassname: "simple-icons:intellijidea",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    // },
    //
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Masachusetts Boston",
      subtitle: "M.S. in Computer Science",
      logo_path: "umb-blue-logo.svg",
      alt_name: "UMB",
      gpa: "3.9/4",
      duration: "2022 - 2023",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Machine Learning, Computer Vision, Neural Networks etc.",
        "⚡ Apart from this, I was a teaching assistant in which I graded papers and helped students clear their doubts.",
        "⚡ I have worked on several software development projects including a python project to create a communication enabler for individuals with special needs.",
      ],
      website_link: "https://www.umb.edu/",
    },
    {
      title: "N.M.A.M. Institute of Technology",
      subtitle: "B.Tech in Computer Science",
      logo_path: "nitte-nmamit-logo.png",
      alt_name: "N.M.A.M. Institute of Texhnology",
      duration: "2017 - 2022",
      gpa: "8.9/10",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like Data Structures, Algorithms, DBMS, OS, Computer Networks, AI etc.",
        "⚡ During my time at the university, I worked on several full stack applications and published an android app.",
        "⚡ I participated and won many of the coding competitions and events held at the university.",
      ],
      website_link: "https://nmamit.nitte.edu.in/",
    },
  ],
};

const certifications = {
  certifications: [
    //   {
    //     title: "",
    //     subtitle: "",
    //     logo_path: "",
    //     certificate_link:
    //       "",
    //     alt_name: "",
    //     color_code: "#8C151599",
    //   },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Projects",
  description:
    "I have worked on a wide scale of projects ranging from frontend development to full-stack applications and native android applications.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Teaching Assistant",
          company: "University of Massachusetts, Boston",
          company_url: "https://www.umb.edu/",
          logo_path: "umb-blue-logo.svg",
          duration: "September 2023 - December 2023",
          location: "Boston, MA",
          description:
            "Supported grading of assignments, delivering constructive feedback to enhance student learning experience and ensure fair evaluation of work for a class of about 100 students. Facilitated weekly office hours to provide students with academic support. Aided in proctoring exams, contributing to the maintenance of academic compliance during testing.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Python Developer Intern",
          company: "Adaptive Concepts LLC",
          company_url:
            "https://adaptiveconceptsllc.wixsite.com/adaptive-concepts-ll",
          logo_path: "adaptive_concepts.webp",
          duration: "January 2023 - May 2023",
          location: "Boston, MA",
          description:
            "Created a communication enabler for individuals with special needs, particularly non-speaking persons, with an intricate translation system to translate keys pressed on a MIDI keyboard to english words and vice-versa. Developed and deployed a user interface allowing custom picture uploads for word association, significantly improving app usability and personalization.",
          color: "#9b1578",
          certificate_text: "Letter of Recommendation",
          certificate_url:
            "https://drive.google.com/file/d/1l9capwuu-XEBcN3egH5A_6XJp4gXS1h8/view?usp=sharing",
        },
        {
          title: "Android Developer Intern",
          company: "K.S. Hegde Medical Academy",
          company_url: "https://kshema.nitte.edu.in/",
          logo_path: "nitte-nmamit-logo.png",
          duration: "January 2021 - May 2021",
          location: "Karnataka, India",
          description:
            "Develop an Android app to facilitate purchase, managing and booking of at home medical lab tests. Used Firebase Database for real-time data synchronization and implemented Firebase Authentication for secure user authentication and authorization, enhancing the application's privacy and security features",
          color: "#ee3c26",
          certificate_text: "Internship certificate",
          certificate_url:
            "https://drive.google.com/file/d/1ib4jyCs-E-1wR6hEq0LTZdSvVAfwRzET/view?usp=sharing",
        },
      ],
    },
    {
      title: "Projects",
      experiences: [
        {
          title: "Full-Stack Developer",
          company: "NMAM Institute of Technology",
          company_url: "https://nmamit.nitte.edu.in/",
          logo_path: "nitte-nmamit-logo.png",
          duration: "August 2021",
          location: "Karnataka, India",
          description:
            "Spearheaded the digitization of a Tulu cognate dictionary using Python, seamlessly integrating Firebase as the backend database for efficient data storage and management.Designed and developed a user-friendly admin page utilizing HTML, CSS, and JavaScript, enabling effortless addition and modification of data within the Firebase database",
          color: "#9b1578",
        },
        {
          title: "Adroid Developer",
          company: "Auto Please",
          company_url:
            "https://play.google.com/store/apps/details?id=chinthan.autoplease&hl=en_US",
          logo_path: "autoplease.webp",
          duration: "August 2020",
          location: "Karnataka, India",
          description:
            "Developed and deployed an Android application to seamlessly connect local auto-rickshaw drivers with customers. Designed an responsive and interactive UI and used Firebase database for real-time updates.",
          color: "#ee3c26",
          certificate_text: "Application Link",
          certificate_url:
            "https://play.google.com/store/apps/details?id=chinthan.autoplease&hl=en_US",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create full-stack applications",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "",
      name: "",
      createdAt: "",
      description: "",
      url: "",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "dhvanith_unadkat.png",
    description: "You can connect with me via LinkedIn or drop me an email.",
  },
  blogSection: {
    title: "Blogs",
    subtitle: "",
    link: "",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "",
    subtitle: "",
    locality: "",
    country: "",
    region: "",
    postalCode: "",
    streetAddress: "",
    // avatar_image_path: "address_image.svg",
    location_map_link: "",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
